import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-3.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import stiliz from "../../assets/images/sp/3/stiliz.svg";
import clovis1 from "../../assets/images/sp/3/clovis-1.jpg";
import clovis2 from "../../assets/images/sp/3/clovis-2.jpg";
import clovis3 from "../../assets/images/sp/3/clovis-3.jpg";
import clovis4 from "../../assets/images/sp/3/clovis-4.jpg";
import clovis5 from "../../assets/images/sp/3/clovis-5.jpg";
import clovis6 from "../../assets/images/sp/3/clovis-6.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Clovis Sail Yacht | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 3</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.clovisyacht.com" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a><br/>
                    <a href="https://www.clovisyacht.com/media/Clovis-Book-2021-IT-np.pdf" target="_blank">
                        <Button icon={<DownloadOutlined/>} className="mb-3">Scarica la brochure</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Una storia lunga 30 anni</h6>
                                <h1>Nata per l'oceano.</h1>
                                <p>Nel 1983, il disegno dell’architetto Dominique Presles prende forma dalle sapienti
                                    mani e dalla vigorosa passione di carpentieri e maestranze del cantiere navale
                                    francese di Biot. E’ la magnifca forma di Clovis, uno yacht a vela di 85 piedi
                                    armato ketch. Costruito in alluminio 6060T5 e con componenti di pregio, concentra
                                    tecnica ed eleganza in un prodotto unico.</p>
                                <p>Nel corso di un recente reft totale, l’esperienza di oltre 30 anni di navigazione ha
                                    reso possibile mantenere e valorizzare queste importanti caratteristiche, oggi
                                    difcili da replicare, e nel contempo conformare questo sail yacht ai più recenti
                                    standard di comfort e sicurezza.</p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={clovis1} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={clovis2} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Vivere il mare</h6>
                                <h1>365 giorni all'anno.</h1>
                                <p>Clovis solca il mare con 380 metri quadrati di vele. La bellezza del mare e dei suoi
                                    venti si rivela viaggiando grazie unicamente alla loro straordinaria forza. La
                                    navigazione a motore fino a 12 nodi garantisce, invece, spostamenti più rapidi
                                    senza nulla togliere al godimento dei fantastici panorami.</p>
                                <p>Clovis non è solo mare e vita a bordo. Con lei si possono raggiungere e godere
                                    numerosi approdi lungo il Mediterraneo: dalle località più note ai turisti fino
                                    alle isole più esclusive. Una cosa è certa: Clovis offre un'esperienza di mare e
                                    di terra unica nel suo genere.</p>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Filosofia</h6>
                                <h1>Il risveglio dei sensi.</h1>
                                <p>Il viaggiatore a bordo di Clovis sa condividere il gusto del veleggiare al tramonto,
                                    del trascorrere notti stellate in splendide baie, del risvegliarsi di fronte a una
                                    spiaggia deserta e dell’assaporare fresche ricette mediterranee. Questo è lo spirito
                                    della vacanza Clovis: vivere esperienze che nella loro apparente semplicità aprono i
                                    sensi a ritrovare l’armonia con sé stessi, con le persone più care e con la
                                    natura.</p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={clovis3} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={clovis4} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Clovis e il suo equipaggio</h6>
                                <h1>Benvenuti a bordo.</h1>
                                <p>Clovis non è solo un’imbarcazione... è anche il suo equipaggio che con amore e
                                    passione la conduce da molto tempo e le dedica cura e attenzioni durante tutto
                                    l’anno. Insieme vivono ed esplorano il mare con grande rispetto alla scoperta delle
                                    sue emozioni più intime e dei suoi segreti più nascosti.</p>
                                <h6 className="fw-bold text-uppercase">Zona giorno e notte</h6>
                                <p>Nella zona giorno Clovis ofre due spaziosi saloni: sala da pranzo e sala relax.
                                    Musica, lettura, tv, dvd e giochi sono a disposizione degli ospiti. La disposizione
                                    della zona notte, ampia e confortevole, è composta di due cabine doppie (con terzo
                                    letto a scomparsa), due cabine matrimoniali (con terzo letto a scomparsa), ognuna
                                    dotata di bagno con doccia. Due ingressi e due aree ben distinte consentono privacy
                                    per gli ospiti e indipendenza per l’equipaggio.</p>
                            </Col>
                        </Row>
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 2}}>
                                <img src={clovis5} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 1}} className="text-justify">
                                <h6 className="fw-bold text-uppercase">Esterni e ponte</h6>
                                <p>Sul ponte un ampio pozzetto consente di stare comodamente seduti su morbidi cuscini
                                    anche durante la navigazione, sorseggiando una fresca bibita o gustando un delizioso
                                    menù, protetti da un tendalino parasole. Al centro del ponte, sulla tuga, non
                                    mancano comodi materassini per sdraiarsi in libertà. Sei metri quadrati di cuscini
                                    “prendisole”, infatti, permettono di godere del massimo relax.</p>
                                <h6 className="fw-bold text-uppercase">Trasferimenti ed escursioni</h6>
                                <p>Clovis dispone di un tender per il trasferimento a e da terra. Il team e l’equipaggio
                                    sono a completa disposizione per trasferimenti da e verso aeroporti o altri punti di
                                    arrivo o partenza degli ospiti. Durante il charter è inoltre possibile efettuare
                                    escursioni nelle località desiderate, previa organizzazione dell’itinerario
                                    personalizzato con il team.</p>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="middle">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <h1>Dettagli tecnici</h1>
                                <h6 className="text-uppercase fw-bold">Vela e motore</h6>
                                <ul>
                                    <li>Yacht in alluminio, armato ketch 85 f (26 m.)</li>
                                    <li>Superfcie velica 380 mq (genoa, randa e mezzana)</li>
                                    <li>Motore FTP 300 HP, Velocità di crociera 10 nodi</li>
                                    <li>Tender 5mt per trasferimenti</li>
                                </ul>
                                <h6 className="text-uppercase fw-bold">Utenze</h6>
                                <ul>
                                    <li>Generatori Kohler 15 kW + 7 kW</li>
                                    <li>Dissalatore (120lt / h)</li>
                                    <li>Serbatoi acqua: 2000 lt / Serbatoi carburante: 2000 lt</li>
                                </ul>
                                <h6 className="text-uppercase fw-bold">Comfort</h6>
                                <ul>
                                    <li>4 cabine ospiti (2 matrimoniali, 2 doppie)</li>
                                    <li>4 bagni ospiti con WC elettrico e doccia</li>
                                    <li>Cabine equipaggio separate</li>
                                    <li>Hi-Fi, TV, cucina attrezzata e lavanderia</li>
                                </ul>
                                <h6 className="text-uppercase fw-bold">Equipaggiamento</h6>
                                <ul>
                                    <li>Pilota automatico, Radar 24 miglia e bussola, Plotter cartografico,
                                        Ecoscandaglio
                                    </li>
                                    <li>VHF, GPS, SSB, Windex e Log</li>
                                </ul>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={clovis6} alt="Clovis"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
